<div *ngIf="show$ | async" aria-labelledby="cookies-terms" class="container" role="region" [@slide]>
  <p class="terms" id="cookies-terms" i18n="@@cookies.AcceptCookiesText">
    By accepting, you agree to store cookies on your device for performance improvements, usage analysis, and marketing
    purposes
    <a href="https://normative.io/bcc-cookie-policy">Learn More</a>
  </p>

  <button class="n-button n-button--primary accept" (click)="accept()" i18n="@@cookies.Accept">Accept</button>
  <button
    class="n-button n-button--primary n-button--outlined configure"
    (click)="configure()"
    i18n="@@cookies.CookieSettings"
  >
    Cookie settings
  </button>
</div>
