import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class StyleguideGuard {
  canActivate() {
    return environment.styleguide === true;
  }
}
