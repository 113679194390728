import { Environment } from './environment.model';

function getAuth0RedirectUrl() {
  const template = 'https://businesscarboncalculator.normative.io/{{locale}}/auth/callback';
  const locale = $localize`:@@locale:en`;
  return template.replace('{{locale}}', locale);
}

export const environment: Environment = {
  production: true,
  styleguide: false,
  localization: true,

  NORMATIVE_DATA_UPLOAD_URL: 'https://accounting-services-data-upload-prod.prod.normative.io',

  AUTH0_CLIENT_ID: 'RfT2BG47GvU6XLnuLnm16YVKow8zTiRr',
  AUTH0_DOMAIN: 'login.normative.io',
  AUTH0_REDIRECT_URI: getAuth0RedirectUrl(),
  AUTH0_AUDIENCE: 'https://normative.eu.auth0.com/api/v2/',

  GA_MEASUREMENT_ID: 'G-YPTWJ6291Q',
  HOTJAR_ID: 2818531,
  HOTJAR_SNIPPET_VERSION: 6,

  SENTRY_DSN: 'https://dc7ed2132e664a6783be2c9252303f20@o55242.ingest.sentry.io/6155125',
  SENTRY_ENVIRONMENT: 'prod',
};
