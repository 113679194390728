import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { Select } from '@ngxs/store';

import { AuthService } from '@auth0/auth0-angular';

import { combineLatest, map, Observable } from 'rxjs';

import { User } from '../core/user/user.model';

import { UserState } from '../core/user/user.state';
import { ONBOARDING_URL } from '../onboarding/onboarding.guard';

export const EMAIL_VERIFICATION_URL = 'email-verification';

@Injectable({ providedIn: 'root' })
export class EmailVerifiedGuard {
  @Select(UserState.user) private user$!: Observable<User>;

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    return combineLatest([
      this.authService
        .getIdTokenClaims()
        .pipe(
          map(
            (claims) =>
              claims?.email_verified || (claims?.must_verify_email !== undefined && !claims?.must_verify_email)
          )
        ),
      this.user$.pipe(map((user) => user?.isEmailVerified)),
    ]).pipe(
      map(([tokenVerifiedFlag, userVerifiedFlag]) => {
        if (route.url.join('') === EMAIL_VERIFICATION_URL) {
          if (tokenVerifiedFlag || userVerifiedFlag) {
            return this.router.parseUrl(ONBOARDING_URL);
          }
        } else if (!tokenVerifiedFlag && !userVerifiedFlag) {
          return this.router.parseUrl(EMAIL_VERIFICATION_URL);
        }
        return true;
      })
    );
  }
}
