<mat-dialog-content>
  <h1 class="title" i18n="@@cookies.CookieSettingsTitle">Cookie settings</h1>
  <p class="intro" i18n="@@cookies.CookieSettingsText">
    When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies.
    This information might be about you, your preferences or your device and is mostly used to make the site work as you
    expect it to. The information does not usually directly identify you, but it can give you a more personalized web
    experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on
    the different category headings to find out more and change our default settings. However, blocking some types of
    cookies may impact your experience of the site and the services we are able to offer.
  </p>

  <form class="form" [formGroup]="form" (submit)="accept()">
    <mat-checkbox formControlName="necessary" id="necessary" i18n="@@cookies.StrictlyNecessaryCookies"
      >Strictly Necessary Cookies</mat-checkbox
    >
    <mat-checkbox formControlName="performance" id="performance" i18n="@@cookies.PerformanceCookies"
      >Performance Cookies</mat-checkbox
    >
    <mat-checkbox formControlName="functional" id="functional" i18n="@@cookies.FunctionalCookies"
      >Functional Cookies</mat-checkbox
    >
    <mat-checkbox formControlName="targeting" id="targeting" i18n="@@cookies.TargetingCookies"
      >Targeting Cookies</mat-checkbox
    >

    <button
      class="n-button n-button--primary save"
      type="submit"
      (click)="logClick('SaveCookiesSettingsClick')"
      i18n="@@cookies.SaveSettings"
    >
      Save settings
    </button>
  </form>
</mat-dialog-content>
