import { HttpErrorResponse } from '@angular/common/http';

import { User } from './user.model';

const FETCH_CURRENT_USER = '[User] FetchCurrentUser';
const FETCH_CURRENT_USER_SUCCEEDED = '[User] FetchCurrentUserSucceeded';
const FETCH_CURRENT_USER_FAILED = '[User] FetchCurrentUserFailed';

const UPDATE_PARTNER = '[User] UpdatePartner';
const UPDATE_PARTNER_SUCCEEDED = '[User] UpdatePartnerSucceeded';
const UPDATE_PARTNER_FAILED = '[User] UpdatePartnerFailed';

const ACCEPT_TERMS = '[User] AcceptTerms';
const ACCEPT_TERMS_SUCCEEDED = '[User] AcceptTermsSucceeded';
const ACCEPT_TERMS_FAILED = '[User] AcceptTermsFailed';

const FETCH_IS_EMAIL_VERIFIED = '[User] FetchIsEmailVerified';
const FETCH_IS_EMAIL_VERIFIED_SUCCEEDED = '[User] FetchIsEmailVerifiedSucceeded';
const FETCH_IS_EMAIL_VERIFIED_FAILED = '[User] FetchIsEmailVerifiedFailed';

const SEND_EMAIL_VERIFICATION = '[User] SendEmailVerification';
const SEND_EMAIL_VERIFICATION_SUCCEEDED = '[User] SendEmailVerificationSucceeded';
const SEND_EMAIL_VERIFICATION_FAILED = '[User] SendEmailVerificationFailed';

const JUST_LOGGED_IN = '[User] JUST_LOGGED_IN';

export class FetchCurrentUser {
  static readonly type = FETCH_CURRENT_USER;
}

export class FetchCurrentUserSucceeded {
  static readonly type = FETCH_CURRENT_USER_SUCCEEDED;
  constructor(public payload: User) {}
}

export class FetchCurrentUserFailed {
  static readonly type = FETCH_CURRENT_USER_FAILED;
  constructor(public payload: HttpErrorResponse) {}
}

export class UpdatePartner {
  static readonly type = UPDATE_PARTNER;
  constructor(public partner: string) {}
}

export class UpdatePartnerSucceeded {
  static readonly type = UPDATE_PARTNER_SUCCEEDED;
  constructor(public payload: User) {}
}

export class UpdatePartnerFailed {
  static readonly type = UPDATE_PARTNER_FAILED;
  constructor(public payload: HttpErrorResponse) {}
}

export class AcceptTerms {
  static readonly type = ACCEPT_TERMS;
  constructor(public bccTerms: boolean) {}
}

export class AcceptTermsSucceeded {
  static readonly type = ACCEPT_TERMS_SUCCEEDED;
  constructor(public payload: User) {}
}

export class AcceptTermsFailed {
  static readonly type = ACCEPT_TERMS_FAILED;
  constructor(public payload: HttpErrorResponse) {}
}

export class SendEmailVerification {
  static readonly type = SEND_EMAIL_VERIFICATION;
}
export class SendEmailVerificationSucceeded {
  static readonly type = SEND_EMAIL_VERIFICATION_SUCCEEDED;
}
export class SendEmailVerificationFailed {
  static readonly type = SEND_EMAIL_VERIFICATION_FAILED;
  constructor(public payload: HttpErrorResponse) {}
}

export class FetchIsEmailVerified {
  static readonly type = FETCH_IS_EMAIL_VERIFIED;
}

export class FetchIsEmailVerifiedSucceeded {
  static readonly type = FETCH_IS_EMAIL_VERIFIED_SUCCEEDED;
  constructor(public isEmailVerified: boolean) {}
}

export class FetchIsEmailVerifiedFailed {
  static readonly type = FETCH_IS_EMAIL_VERIFIED_FAILED;
  constructor(public payload: HttpErrorResponse) {}
}

export class JustLoggedIn {
  static readonly type = JUST_LOGGED_IN;
  constructor(public justLoggedIn?: boolean) {}
}
