export interface BuildStamp {
  commit: string;
  branch: string;
  timestamp: string;
  github_repo: string;
  github_org: string;
}

export const BUILD_STAMP: BuildStamp = ({
  "commit": "28a571ca3f485c78e9cf0e7dd0da9c0afd4bee20",
  "branch": "",
  "timestamp": "2024-08-26T13:48:27.635Z",
  "github_repo": "business-carbon-calculator",
  "github_org": "normative-io"
});
