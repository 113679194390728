import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { DateTime } from 'luxon';
import { catchError, mergeMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { OrganizationAccountType } from '../organization/organization.model';
import { getDateTime } from '../utils/dates.utils';

import {
  AcceptTerms,
  AcceptTermsFailed,
  AcceptTermsSucceeded,
  FetchCurrentUser,
  FetchCurrentUserFailed,
  FetchCurrentUserSucceeded,
  FetchIsEmailVerified,
  SendEmailVerification,
  SendEmailVerificationFailed,
  SendEmailVerificationSucceeded,
  UpdatePartner,
  UpdatePartnerFailed,
  UpdatePartnerSucceeded,
  FetchIsEmailVerifiedFailed,
  FetchIsEmailVerifiedSucceeded,
  JustLoggedIn,
} from './user.actions';
import { OrganizationAccountSnapshot, User, UserStateModel } from './user.model';

const USER_STATE_TOKEN = new StateToken<UserStateModel>('user');
const USER_STATE_DEFAULTS: UserStateModel = { error: null, user: null };

/** The time we last updated our T&Cs (used to re-ask acceptence). */
export const LAST_UPDATED_TERMS = DateTime.utc(2022, 3, 15);

@State({
  name: USER_STATE_TOKEN,
  defaults: USER_STATE_DEFAULTS,
})
@Injectable()
export class UserState {
  @Selector()
  static user(state: UserStateModel): User | null {
    return state.user;
  }

  @Selector()
  static justLoggedIn(state: UserStateModel): boolean | undefined {
    return state.user?.justLoggedIn;
  }

  @Selector()
  static error(state: UserStateModel): Error | null {
    return state.error;
  }

  @Selector([UserState.user])
  static organization(state: UserStateModel, user: User | null): OrganizationAccountSnapshot | null {
    return (
      user?.organizationAccounts?.find(({ accountType }) => accountType === OrganizationAccountType.STARTER) || null
    );
  }

  @Selector([UserState.user])
  static acceptedTerms(state: UserStateModel, user: User | null): boolean {
    return user?.bccTerms ? getDateTime(user.bccTerms) >= LAST_UPDATED_TERMS : false;
  }

  @Selector([UserState.user])
  static partner(state: UserStateModel, user: User | null): string | false {
    return user?.partner ?? false;
  }

  constructor(private http: HttpClient) {}

  @Action(FetchCurrentUser)
  fetchCurrentUser(ctx: StateContext<UserStateModel>) {
    return this.http.get<User>(`${environment.NORMATIVE_DATA_UPLOAD_URL}/users/me`).pipe(
      mergeMap((user) => ctx.dispatch(new FetchCurrentUserSucceeded(user))),
      catchError((error) => ctx.dispatch(new FetchCurrentUserFailed(error)))
    );
  }

  @Action(FetchCurrentUserSucceeded)
  fetchCurrentUserSucceeded(ctx: StateContext<UserStateModel>, { payload }: FetchCurrentUserSucceeded) {
    return ctx.patchState({ user: payload });
  }

  @Action(FetchCurrentUserFailed)
  fetchCurrentUserFailed(ctx: StateContext<UserStateModel>, { payload }: FetchCurrentUserFailed) {
    return ctx.patchState({ error: payload });
  }

  @Action(UpdatePartner)
  updateCurrentUser(ctx: StateContext<UserStateModel>, { partner }: UpdatePartner) {
    const url = `${environment.NORMATIVE_DATA_UPLOAD_URL}/users/me/partner`;
    return this.http.put<User>(url, { partner }).pipe(
      mergeMap((user) => ctx.dispatch(new UpdatePartnerSucceeded(user))),
      catchError((error) => ctx.dispatch(new UpdatePartnerFailed(error)))
    );
  }

  @Action(UpdatePartnerSucceeded)
  updatePartnerSucceeded(ctx: StateContext<UserStateModel>, { payload }: AcceptTermsSucceeded) {
    return ctx.patchState({ user: { ...ctx.getState().user, ...payload } });
  }

  @Action(UpdatePartnerFailed)
  updatePartnerFailed(ctx: StateContext<UserStateModel>, { payload }: AcceptTermsFailed) {
    return ctx.patchState({ error: payload });
  }

  @Action(AcceptTerms)
  acceptTerms(ctx: StateContext<UserStateModel>, { bccTerms }: AcceptTerms) {
    const id = ctx.getState().user?._id || ''; // User will always exist at this point
    const url = `${environment.NORMATIVE_DATA_UPLOAD_URL}/users/${id}/bccTerms`;

    return this.http.put<User>(url, { bccTerms }).pipe(
      mergeMap((user) => ctx.dispatch(new AcceptTermsSucceeded(user))),
      catchError((error) => ctx.dispatch(new AcceptTermsFailed(error)))
    );
  }

  @Action(AcceptTermsSucceeded)
  acceptTermsSucceeded(ctx: StateContext<UserStateModel>, { payload }: AcceptTermsSucceeded) {
    return ctx.patchState({ user: { ...ctx.getState().user, ...payload } });
  }

  @Action(AcceptTermsFailed)
  AcceptTermsFailed(ctx: StateContext<UserStateModel>, { payload }: AcceptTermsFailed) {
    return ctx.patchState({ error: payload });
  }

  @Action(SendEmailVerification)
  sendEmailVerification(ctx: StateContext<UserStateModel>) {
    const url = `${environment.NORMATIVE_DATA_UPLOAD_URL}/users/resend-verification-email`;

    return this.http.post<User>(url, {}).pipe(
      mergeMap(() => ctx.dispatch(new SendEmailVerificationSucceeded())),
      catchError((error) => ctx.dispatch(new SendEmailVerificationFailed(error)))
    );
  }

  @Action(FetchIsEmailVerified)
  fetchIsEmailVerified(ctx: StateContext<UserStateModel>) {
    const url = `${environment.NORMATIVE_DATA_UPLOAD_URL}/users/is-email-verified`;

    return this.http.get<boolean>(url).pipe(
      mergeMap((isEmailVerified) => ctx.dispatch(new FetchIsEmailVerifiedSucceeded(isEmailVerified))),
      catchError((error) => ctx.dispatch(new FetchIsEmailVerifiedFailed(error)))
    );
  }

  @Action(FetchIsEmailVerifiedSucceeded)
  fetchIsEmailVerifiedSucceeded(ctx: StateContext<UserStateModel>, { isEmailVerified }: FetchIsEmailVerifiedSucceeded) {
    return ctx.patchState({ user: { ...(ctx.getState().user as User), isEmailVerified } });
  }

  @Action(FetchIsEmailVerifiedFailed)
  fetchIsEmailVerifiedFailed(ctx: StateContext<UserStateModel>, { payload }: FetchIsEmailVerifiedFailed) {
    return ctx.patchState({ error: payload });
  }

  @Action(SendEmailVerificationSucceeded)
  updateOrganizationSucceeded(ctx: StateContext<UserStateModel>) {
    return ctx.patchState({ error: null });
  }

  @Action(SendEmailVerificationFailed)
  sendEmailVerificationFailed(ctx: StateContext<UserStateModel>, { payload }: SendEmailVerificationFailed) {
    return ctx.patchState({ error: payload });
  }

  @Action(JustLoggedIn)
  justLoggedIn(ctx: StateContext<UserStateModel>, { justLoggedIn }: JustLoggedIn) {
    return ctx.patchState({ user: { ...(ctx.getState().user as User), justLoggedIn } });
  }
}
