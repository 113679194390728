import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';

import { EmailVerifiedGuard } from './email-verification/email-verification.guard';

import { NotFoundComponent } from './not-found/not-found.component';
import { NotFoundModule } from './not-found/not-found.module';
import { OnboardingGuard } from './onboarding/onboarding.guard';
import { StyleguideGuard } from './styleguide/styleguide.guard';

const BASE_SIGNUP_PATH = 'signup';
const BASE_AUTH_PATH = 'auth/signup';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'wizard',
    loadChildren: () => import('./wizard/wizard.module').then((m) => m.WizardModule),
    canActivate: [AuthGuard, EmailVerifiedGuard, OnboardingGuard],
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
    canActivate: [AuthGuard, EmailVerifiedGuard, OnboardingGuard],
  },
  {
    path: `${BASE_SIGNUP_PATH}/lfca`,
    redirectTo: `${BASE_AUTH_PATH}/lfca`,
  },
  {
    path: `${BASE_SIGNUP_PATH}/nordea`,
    redirectTo: `${BASE_AUTH_PATH}/nordea`,
  },
  {
    path: `${BASE_SIGNUP_PATH}/zürich`,
    redirectTo: `${BASE_AUTH_PATH}/zürich`,
  },
  {
    path: `${BASE_SIGNUP_PATH}/zurich`,
    redirectTo: `${BASE_AUTH_PATH}/zürich`,
  },
  {
    path: `${BASE_SIGNUP_PATH}/intuit`,
    redirectTo: `${BASE_AUTH_PATH}/intuit`,
  },
  {
    path: `${BASE_SIGNUP_PATH}/catapult`,
    redirectTo: `${BASE_AUTH_PATH}/catapult`,
  },
  {
    path: `${BASE_SIGNUP_PATH}/vce`,
    redirectTo: `${BASE_AUTH_PATH}/vce`,
  },
  {
    path: BASE_SIGNUP_PATH,
    redirectTo: BASE_AUTH_PATH,
  },
  {
    path: 'styleguide',
    loadChildren: () => import('./styleguide/styleguide.module').then((m) => m.StyleguideModule),
    canActivate: [AuthGuard, StyleguideGuard],
  },
  {
    path: 'licenses',
    loadChildren: () => import('./licenses/licenses.module').then((m) => m.LicensesModule),
  },
  {
    path: 'email-verification',
    loadChildren: () => import('./email-verification/email-verification.module').then((m) => m.EmailVerificationModule),
    canActivate: [AuthGuard, EmailVerifiedGuard],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [NotFoundModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
